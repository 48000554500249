import { Currency, IItemVariant } from '@rediredi/types'
import { CartItem } from '~/types/cart'
import { IStorefrontItem } from '~/types/item'

export const toCartItem = (
  item: IStorefrontItem,
  quantity: number,
  variant: IItemVariant,
  currency?: Currency,
): CartItem => {
  const pictures =
    item.options?.length && variant.pictures?.length ? variant.pictures : item.pictures

  return {
    id: item.id,
    pictures: pictures || [],
    title: item.baseVariant.title as string,
    quantity,
    quantityAvailable: variant.quantityAvailable ?? 1,
    price: {
      ...item.baseVariant.price!,
      currency: currency || item.baseVariant.price?.currency || Currency.BRL,
    },
    promotionPrice: item.baseVariant.promotionPrice
      ? {
          ...item.baseVariant.promotionPrice,
          currency: currency || item.baseVariant.promotionPrice?.currency || Currency.BRL,
        }
      : undefined,
    promotion: item.promotion,
    variantId: variant.id || item.id,
    optionMapping: variant.optionMapping,
  }
}

export const itemTotal = (item: CartItem, currency?: Currency) => {
  const amount = item.price?.amount ?? 0
  const quantity = item.quantity
  return formatPriceInParts(amount * quantity, {
    currency: currency || item.price?.currency || Currency.BRL,
  })
}
